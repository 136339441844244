import {store} from '@/store/store.js'
import {resetResizerValues} from '@/utils/ColumnsResizer' 
const Socials = () => import(/* webpackChunkName: "socials" */ '@/views/Socials.vue')
export const socials = {
    path: '/socials',
    name: 'socials',
    component: Socials,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        store.commit('social/clear_filters')
        await store.dispatch('social/list')
        resetResizerValues('social')
        
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }
  export const socialByID = {
    path: '/social/:id',
    name: 'social',
    component: Socials,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        store.commit('social/clear_filters')
        if(store.getters['social/social'].length === 0){
          await store.dispatch('social/list')
        }
       
        resetResizerValues('social')
        
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }