<template>
    <header v-if="me" class="header">
        <div class="flex-container flex-col">
            <div class="header-username"><span class="label">{{me.roles[0]}} : </span>{{me.userName}}</div>
            <div v-if="me?.userScope?.concessionaire" class="header-username-details f12">
                <span class="label">Concessionaria: </span> {{me.userScope.concessionaire.name}}
            </div>
        </div>
        <div class="flex-container flex-col f12">
            <div class="header-credits">&copy; 2021 <a href="" class="url-inflooendo">Inflooendo</a></div>
            <div class="header-credits">Powered by <a href="" class="url-platypus">Platypus</a></div>
        </div>
    </header>
</template>
<script>
import {inject} from 'vue'
export default {
    setup(){
        
        const me = inject('me')
        
        return {
            me
        }
    }
}
</script>