import {store} from '@/store/store'
import {computed} from 'vue'
import router from '@/router/index'
import { 
    MESSAGE_TITLE, 
    MESSAGE, 
    MESSAGE_CLOSE, 
    DO_LOGOUT, 
    MESSAGE_SHOW 
  } from '@/store/VuexPaths'

export const showConfirm = computed(()=>store.getters[MESSAGE_SHOW])
export const message = computed(()=>store.getters[MESSAGE])
export const messageTitle = computed(()=>store.getters[MESSAGE_TITLE])

export const onCloseMessage = async (msg) => {
  await store.dispatch(MESSAGE_CLOSE)
  if(msg === 'Request failed with status code 401'){
    await store.dispatch(DO_LOGOUT)
    router.push('/login')

  }
}