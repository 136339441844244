<template>
    <label class="flex-container flex-col flex-0 mb8">
        <div class="flex-container heading-and-error flex-start-align no-grow">
            <h3 class="bebas nomargin">{{label}}</h3>
            <ErrorMessage v-if="editing" :validationStatus="vuel.uemail" />
        </div>
        
        <input
            v-if="editing" 
            required 
            v-model="uemail" 
            name="email" 
            type="email" 
            :placeholder="placeholder"
            class="noborder nopadding pl8"
            @input="()=>$emit('input', {text:uemail || ''})"
            @blur="handleBlur('uemail')"
        />
        <input 
            v-else 
            type="email" 
            :placeholder="placeholder"  
            class="noborder nopadding pl8" 
            disabled 
            :value="email"
        >
    </label>
    
</template>
<script>
import { ref } from 'vue'
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators"
import ErrorMessage from "./ErrorMessage";
export default {
    props:{
        label:{
            type: String,
            default:''
        },
        placeholder:{
            type:String,
            default:'email'
        },
        email:{
            type:String,
            default:''
        },
        editing:{
            type:Boolean,
            default:true
        }
    },
    components:{
        ErrorMessage
    },
    setup(props){
        const uemail = ref(props.email);
        
        const rules = { uemail: {required, email} }

        const vuel = useVuelidate(rules,{ uemail })

        const handleBlur = (key) =>{
            vuel.value[key].$dirty = true;
        }
        
        return{
            vuel,
            handleBlur,
            uemail
        }
    }
}
</script>