import {computed} from 'vue'
import {  IS_LOGGED/* , CURRENT_ROLE */, ME, REAL_ROLE } from './../store/VuexPaths'
import {
  POLICY_MANAGE_USERS, 
  POLICY_MANAGE_TAG,
  POLICY_MANAGE_CAMPAIGN,
  POLICY_MANAGE_SOCIAL,
  POLICY_MANAGE_USERSCOPE,
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_ADVERTISER,
  USER_ROLE_PUBLISHER_ADMIN,
  USER_ROLE_PUBLISHER_USER,
  USER_ROLE_CONCESSIONAIRE_USER,
  USER_ROLE_CONCESSIONAIRE_ADMIN
} from '../utils/RolesAndPolicies'

import {store} from '@/store/store'

const me = computed(()=>{
    return store.getters[ME]
})
const currentRole= computed(()=>{
    return store.getters['auth/currentMe']
})
const realRole= computed(()=>{
    return store.getters[REAL_ROLE]
})

const realMe = computed(()=>{
    return store.getters['auth/realMe']
})
const mocking = computed(()=>{
    return store.getters['auth/mocking']
})
const canManageUsers = computed(() => {
    return currentRole.value.policies.find(p => p === POLICY_MANAGE_USERS) !== undefined
})

const canManageTags = computed(() => {
    
    return currentRole.value.policies.find(p => p === POLICY_MANAGE_TAG) !== undefined
    
})
const canManageCampaigns = computed(() => {
    
    return currentRole.value.policies.find(p => p === POLICY_MANAGE_CAMPAIGN) !== undefined
})
const canManageSocials = computed(() => {
    return currentRole.value.policies.find(p => p === POLICY_MANAGE_SOCIAL)  !== undefined 
})
const canManageScopes = computed(() => {
    return currentRole.value.policies.find(p => p === POLICY_MANAGE_USERSCOPE) !== undefined 
})

const isLoggedIn =computed(()=>{
    return store.getters[IS_LOGGED]      
})

const isAdmin = () =>{
    return currentRole.value.roles[0] === USER_ROLE_SUPER || currentRole.value.roles[0] === USER_ROLE_ADMIN
}
const isAdvertiser = ()=>{
    return currentRole.value.roles[0] === USER_ROLE_ADVERTISER
}
const isPublisher = () =>{
    return currentRole.value.roles[0] === USER_ROLE_PUBLISHER_ADMIN || currentRole.value.roles[0] === USER_ROLE_PUBLISHER_USER
}
const isConcessionaire = () =>{
    return currentRole.value.roles[0] === USER_ROLE_CONCESSIONAIRE_ADMIN || currentRole.value.roles[0] === USER_ROLE_CONCESSIONAIRE_USER
}

export const useCapabilities = () => {
    return {
        me,
        currentRole,
        realRole,
        canManageUsers,
        canManageTags,
        canManageCampaigns,
        canManageSocials,
        canManageScopes,
        isLoggedIn,
        realMe,
        mocking,
        isAdmin,
        isAdvertiser,
        isPublisher,
        isConcessionaire
    }
}