import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'

const proposed = async({commit}) => {
  
    commit('request')
    try {
      const {data, headers}  = await API.get(ApiEndPoints.SOCIAL_CAMPAIGNS+'/proposed')
      const pagination = JSON.parse(headers['x-pagination'])
      commit('proposed_success', {data, pagination}) 
    } catch (error) {
      commit('get_error')
      console.log(error)
      throw new Error(error.message)
    }
  }

  export const actions = {
      proposed
  }