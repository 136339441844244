
export const message_hide =(state)=>{
    state.show = false
    state.title=null
    state.message=null
}

export const message_show = (state, {msg})=>{
    const {title, message} = msg
    state.show = true
    state.title = title
    state.message = message
}

export const mutations = {
    message_show,
    message_hide
}
