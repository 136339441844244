import { mutations } from './mutations';
import { actions } from './actions';

export const userModule = {
    state () {
        return {
          //editing:false,  
          users:[],
          currentRole:null,
          filtered:null,
          activeFilters:[],
          columnsSizes:null,
          pagination:null,
          visitedPages:[]
        }
    },
    namespaced: true,
    getters: {
        users: state => state.users,
        paged: state => {

            const currentPage = state.pagination.CurrentPage
            const pageSize = state.pagination.PageSize
            const offset = (currentPage * pageSize) - pageSize
            return state.users.filter((tag, index) => (index >= offset && index <= offset + pageSize -1))
        },
        filtered:state=>state.filtered,
        activeFilters:state=>state.activeFilters,
        //editing:state=>state.editing,
        currentRole:state =>{
            return state.currentRole ? state.currentRole : state.selected.roles[0]
        },
        columnsSizes:state=>state.columnsSizes,
        pagination:state=>state.pagination,
        
    },
    mutations,
    actions
}