<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel ">
                <div class="flex-container flex-col h100" id="panel">
                    <div class="panel-header p24">
                        <div class="mini-logo"></div>
                        <h2 class="panel-title nomargin bebas">Impersona</h2>
                    </div>
                    <div class="flex-container p16 no-grow">
                        <label class="flex-container flex-col pt8">
                            <div class="flex-container heading-and-error">
                            <h3 class="bebas nomargin">Filtra  Per nome</h3>
                            </div>
                            
                            <input  
                                v-model="userName" 
                                type="text" 
                                placeholder="Inserisci il nome dell' utente"
                                @input="userNameSearchChange"
                                class="noborder nopadding pl8"
                            />
                        
                        </label>
                    </div>
                    
                    <div class="flex-container p16 no-grow">
                        <label class="flex-container flex-col pr8">
                            <div class="flex-container heading-and-error">
                                <h3 class="bebas nomargin">Filtra  Per Tipologia</h3>
                            </div>
                            <select  
                                name="searchtype" 
                                id="searchtype" 
                                class="noborder nopadding" 
                                @change="userTypeSelected" 
                                v-model="userType" 
                            >
                                <option selected :value="null">{{userType === null ? 'Seleziona Tipologia' : '---elimina filtro---'}}</option>
                                <option  value="Publisher">Publisher</option>
                                <option  value="Concessionaire">Concessionaire</option>
                                <option  value="Advertiser">Advertiser</option>
                            </select>
                        </label>

                        <label class="flex-container flex-col pr8">
                            <div class="flex-container heading-and-error">
                                <h3 class="bebas nomargin">Filtra per Ruolo</h3>
                            </div>
                            <select  
                                name="searchrole" 
                                id="searchrole" 
                                class="noborder nopadding" 
                                @change="userRoleSelected" 
                                v-model="userRole"
                                :disabled="userType === null || (userType.startsWith('Ad'))"
                            >
                                <option selected :value="null">{{userRole === null ? 'Seleziona ruolo' : '---elimina filtro---'}}</option>
                                <option v-for="(role, index) in possibleRoles" :key="role+'-'+index" :value="role">{{role}}</option>
                            </select>
                        </label>
                    </div>
                    <div class="flex-container p16 no-grow">
                        
                         <AjaxSearchSelect
                            ref='concessionaireSelect'
                            label="Filtra per concessionaria"
                            :feedUrl="ApiEndPoints.USER_SCOPE"
                            :params="{discriminator:USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR}"
                            @select="concessionaireSelected"
                            @deselect="concessionaireDeselected"
                            :selected="concessionaire"
                            :className="'flex-container flex-col'"
                        >
                        </AjaxSearchSelect>
                        <label class="flex-container flex-col ">
                            <div class="flex-container heading-and-error flex-start-align no-grow">
                                <h3 class="bebas nomargin">Filtra Per Publisher</h3>
                            </div>
                            <select  
                                name="searchpub" 
                                id="searchpub" 
                                class="noborder nopadding pl8 w100" 
                                @change="publisherSelected" 
                                v-model="publisher"
                                :disabled="concessionaire === null"
                            >
                                <option selected :value="null">{{publisher === null ? 'Seleziona publisher' : '---elimina filtro---'}}</option>
                                <option v-for="publisher in publishers" :key="publisher.name" :value="publisher.id">{{publisher.name}}</option>
                            </select>
                            
                        </label>
                    </div>
                    <div class="flex-container flex-col scrollable-content" ref="scrollingPanel">
                        <div class="flex-container flex-col">
                            <ul class="layout-list nopadding nomargin">
                                <UserListElement v-for="user in users" :key="user.id" mode="button" :user="user" @click.prevent="()=>userSelected(user)"></UserListElement>
                            </ul>
                        </div>
                    </div>
                    <div class="flex-container form-footer pt8">
                        <input type="reset" value="reset" class="w100 noborder nopadding bebas" @click.prevent="onReset">
                        <input type="submit" value="chiudi" class="w100 noborder nopadding bebas" @click.prevent="emit('close')">
                    </div> 
                </div>
                
            </div> 
         </div>
      
    </teleport>
</template>

<script>
import {ref, onMounted, reactive, computed, onUnmounted} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {Roles} from '@/utils/RolesAndPolicies'
import {  MESSAGE } from '@/store/VuexPaths'
import UserListElement from './UserListElement'
import{useCapabilities}from '@/utils/Capabilities'
import { ApiEndPoints/* , API_BASE */ } from '@/api/Endpoints';
//import {USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR} from '@/utils/RolesAndPolicies'
import {apiUserFunction} from '@/api/users'
import {list as scopesList, apiUserScopeFunction} from '@/api/userscopes'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import { USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR } from '@/utils/RolesAndPolicies'
export default {
    emits:['close'],
    components:{
        UserListElement,
        AjaxSearchSelect
    },
    setup(_, {emit}){
        const store = useStore()
        const router = useRouter()
        const {isAdmin, isAdvertiser , isPublisher, isConcessionaire} = useCapabilities()
        const userName = ref(null)
        const userType = ref(null)
        const userRole = ref(null)
        const concessionaire = ref(null)
        const concessionaireSelect = ref(null)
        const publisher = ref(null)
        const users = ref(null)
        const scopes = ref(null)
        const concessionaires = ref(null)
        const scrollingPanel = ref(null)
        const currentPage = ref(0)
        const paginationData = reactive({
            TotalCount:0,
            PageSize:20,
            CurrentPage:1,
            TotalPages:1,
            HasNext:false,
            HasPrevious:false,
        })
        //const keyCounter = ref(0)
        
        const possibleRoles = computed(()=>{
           const roles = Roles.map(r => r.name).filter(r => r !== 'Superuser' && r !== 'Admin')
           if(userType.value !== null){
               return roles.filter(r => r.startsWith(userType.value))
           }
           return roles
        })

        const updatePaginationData = (newData) => {
            paginationData.TotalCount = newData.TotalCount
            paginationData.PageSize = newData.PageSize
            paginationData.CurrentPage = newData.CurrentPage
            paginationData.TotalPages = newData.TotalPages
            paginationData.HasNext = newData.HasNext
            paginationData.HasPrevious = newData.HasPrevious
        }

        const getUsers = async (params = {}, endpoint = ApiEndPoints.USERS) =>{
 
            if(endpoint.startsWith(ApiEndPoints.USERS) && ! endpoint.startsWith(ApiEndPoints.USER_SCOPE)){
                const {data, pagination} = await apiUserFunction.list(params, endpoint)
                if(!users.value || pagination.CurrentPage ===1){
                    users.value = data
                } else{
                    data.forEach(el =>{
                        users.value.push(el)
                    })
                }
                
                updatePaginationData(pagination)
                currentPage.value = pagination.CurrentPage
            }
            if(endpoint.startsWith(ApiEndPoints.USER_SCOPE)){
                const {data, pagination} = await apiUserScopeFunction.list(params, endpoint)
                if(!users.value || pagination.CurrentPage ===1){
                    users.value = data
                } else{
                    data.forEach(el =>{
                        users.value.push(el)
                    })
                }
                updatePaginationData(pagination)
                currentPage.value = pagination.CurrentPage
            }
        }

        const onPanelScroll = async (e) => {
            const bottomReach = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1
           
            if(bottomReach && paginationData.HasNext){
                await getUsers({PageNumber:paginationData.CurrentPage+1}, endpointUsers) 
            }
        }

        onMounted(async ()=>{
            try {    
                await getUsers()
             } catch (error) {
                await store.dispatch(MESSAGE, { 
                   title:'Bad request', 
                   message:error.message 
                })   
            }
            scrollingPanel.value.addEventListener('scroll', onPanelScroll)
        })
        onUnmounted(()=>{
            //scrollingPanel.value.removeEventListener('scroll', onPanelScroll)
        })

        const publishers = ref(null)
        
        const userNameSearchChange = () =>{
            filterChanged(userName, 'name')
        }
        const userTypeSelected = () =>{
           filterChanged(userType, 'type')
        }
        const userRoleSelected = () =>{ 
           // getUsers({roles:userRole.value})

           filterChanged(userRole, 'role')
        }
        const concessionaireSelected = async(conc) =>{
            concessionaire.value = conc
            const { data } = await scopesList({concessionaireId:concessionaire.value.id} )
            publishers.value = data.length > 0 ? data : null
            const index = activeFilters.findIndex(f=>f.name === 'publisher')
            if(index !== -1){
               activeFilters.splice(index, 1)
            }
            publisher.value = null
            filterChanged(concessionaire, 'concessionaire')
        }
        const concessionaireDeselected = async() =>{
            concessionaire.value = null
            filterChanged(concessionaire, 'concessionaire')
        }
        const publisherSelected = () =>{
           filterChanged(publisher, 'publisher')
        }

        let endpointUsers
        const filterChanged = async(ref, key) => {
            const index = activeFilters.findIndex(f=>f.name === key)
            if(ref.value === '' || ref.value === null){
                if(index !== -1){
                    activeFilters.splice(index, 1);
                }
            } else {
                if(index === -1){
                    activeFilters.push({name:key, value:ref.value})
                } else {
                    activeFilters.find(f=>f.name === key).value = ref.value
                }
            }
            
            const params = {}

            endpointUsers =  ApiEndPoints.USERS
            const len = activeFilters.length
            let selected
            let search = activeFilters.find(f => f.name === 'name')
            let type = activeFilters.find(f => f.name === 'type')
            let role = activeFilters.find(f => f.name === 'role')
            let pub = activeFilters.find(f => f.name === 'publisher')
            let conc = activeFilters.find(f => f.name === 'concessionaire')

            
            if(len > 0){
                 switch(key){
                    case "name":
                        
                        if(search){
                             endpointUsers+=`?search=${ref.value}`
                        } 
                        if(type){
                           search ? endpointUsers+=`&roles=${type.value}Admin&roles=${type.value}User` : endpointUsers+=`?roles=${type.value}Admin&roles=${type.value}User`
                        }
                        if(role){
                           search ? endpointUsers+=`&roles=${role.value}` : endpointUsers+=`?roles=${role.value}`
                        }
                        if(pub){
                            search ? endpointUsers+=`&userScope=${pub.value}` : endpointUsers+=`?userScope=${pub.value}`
                        }    
                        
                        break;
                    case "type":
                        if(type){
                                if(ref.value !== 'Advertiser'){
                                endpointUsers+=`?roles=${ref.value}Admin&roles=${ref.value}User`
                            } else {
                                endpointUsers+=`?roles=${ref.value}`
                            }  
                            // eslint-disable-next-line no-case-declarations
                            let concIndx = activeFilters.findIndex(f=>f.name === 'concessionaire')
                            if(concIndx !== -1){
                                activeFilters.splice(concIndx, 1)
                                concessionaire.value = null 
                                concessionaireSelect.value.multiselect.clear()
                            }
                            // eslint-disable-next-line no-case-declarations
                            let pubIndx = activeFilters.findIndex(f=>f.name === 'publisher')
                            if(pubIndx !== -1){
                                activeFilters.splice(pubIndx, 1)
                                publisher.value = null 
                                publishers.value = null
                            }
                        }
                        if(search){
                            type ? endpointUsers+='&search='+search.value :endpointUsers+='?search='+search.value
                        }

                        break;
                    case "concessionaire":
                        if(conc){
                            endpointUsers = ApiEndPoints.USER_SCOPE +'/'+ ref.value.id + '/user'
                        }
                        if(search){
                            endpointUsers+='?search='+search.value
                        }
                        // eslint-disable-next-line no-case-declarations
                        let typeIndx = activeFilters.findIndex(f=>f.name === 'type')
                        if(typeIndx !== -1){
                            activeFilters.splice(typeIndx, 1)
                            userType.value = null 
                        }
                        // eslint-disable-next-line no-case-declarations
                        let roleIndx = activeFilters.findIndex(f=>f.name === 'role')
                        if(roleIndx !== -1){
                            activeFilters.splice(roleIndx, 1)
                            userRole.value = null 
                        }
                        break;
                    case "publisher":

                        selected =  publishers.value.find(p => p.id === ref.value)
                        if(selected){
                            endpointUsers = ApiEndPoints.USER_SCOPE +'/'+ selected.id + '/user'
                        }
                        if(search){
                            endpointUsers+='?search='+search.value
                        }
                        break;
                    case "role":
                        if(role){
                            endpointUsers+=`?roles=${ref.value}`
                        } else if(type){
                            endpointUsers+=`?roles=${type.value}Admin&roles=${type.value}User`
                        }
                        if(search){
                            role || type  ? endpointUsers+='&search='+search.value :endpointUsers+='?search='+search.value
                        }
                        // eslint-disable-next-line no-case-declarations
                            let concIndx = activeFilters.findIndex(f=>f.name === 'concessionaire')
                            if(concIndx !== -1){
                                activeFilters.splice(concIndx, 1)
                                concessionaire.value = null 
                                concessionaireSelect.value.multiselect.clear()
                            }
                            // eslint-disable-next-line no-case-declarations
                            let pubIndx = activeFilters.findIndex(f=>f.name === 'publisher')
                            if(pubIndx !== -1){
                                activeFilters.splice(pubIndx, 1)
                                publisher.value = null 
                                publishers.value = null
                            }
                        //params.roles = ref.value
                        break;
                    
                }
            }
           
            await getUsers(params, endpointUsers)

                        

        }

        const activeFilters =  reactive([])
        
        const userSelected = async (user) => {
            await store.dispatch('auth/switchRole', user)
            await store.dispatch('auth/alterEgo')

             if(isAdmin()){
                router.push('/')
            } else if (isAdvertiser()){
                store.commit('campaigns/clear')
                await store.dispatch('campaigns/index')
                router.push('/campaigns')
            } else if(isPublisher() || isConcessionaire()){
                store.commit('campaigns/clear')
                store.commit('social/social_clear')
                store.commit('user/users_clear')
                await store.dispatch('social/list')
                router.push('/socials')
            }
            
            
        }


        const onReset = async()=>{
            while(activeFilters.length > 0){
                activeFilters.splice(0, 1);
            }
            await getUsers()
            //keyCounter.value++
        }
        return{
            possibleRoles,
            userName,
            userNameSearchChange,
            userType,
            userTypeSelected,
            userRole,
            userRoleSelected,
            concessionaire,
            concessionaireSelect,
            concessionaireSelected,
            concessionaireDeselected,
            publisher,
            publisherSelected,
            users,
            scopes,
            publishers,
            concessionaires,
            activeFilters,
            filterChanged,
            emit,
            userSelected,
            ApiEndPoints,
            USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR,
            scrollingPanel,
            onReset
        }
    }
}
</script>
<style scoped>
/**temp */
.overlay-panel{
        height: 80%;
    /* overflow-y: scroll; */
}
.overlay-panel .flex-container.form-footer{
    flex-grow: 0;
    justify-content: space-between;
}
#panel{
    box-sizing: border-box;
}
.flex-container.flex-col.scrollable-content {
    overflow-y: auto;
}
</style>