import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'
import {useCapabilities} from '@/utils/Capabilities'
const index = async({commit})=>{
    commit('request')
    try {
        const {data} = await API.get(ApiEndPoints.POST_FB)
        commit('get_success' ,{ data })
        return data
    } catch (error) {
        commit('get_error')
        throw new Error(error.message)
    }
}

const facebookSearch = async({commit},payload)=>{
  const {socialId,data} = payload
  const params = data ? data : {}
  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/facebook' : ApiEndPoints.POST_FB
    const response = await API.post(endpoint+'/'+ socialId+'/search', params)
    commit('facebook_search_success', response) 
    return response
  } catch (error) {
    commit('facebook_error')
    if(error.message.startsWith('Invalid OAuth access token.') || error.message.startsWith('(#200) Provide valid app ID') ) {
      const socialName =  error.message.split('|')[2]
      commit('reset/facebook_reset_oauth', {socialId, socialName},{ root: true })
    }
    throw new Error(error.message)
  }
}

/**
 * Facebook
 */


const facebookAssignPost = async({commit},data)=>{
  commit('request')
  try {
    
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/post/facebook' : ApiEndPoints.POST_FB
    
    const response = await API.post(endpoint, data) 
    commit('facebook_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const removeFacebookPost = async({commit}, postId)=>{
  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/post/facebook' : ApiEndPoints.POST_FB
    await API.delete(endpoint+'/'+ postId )
    //await API.delete(ApiEndPoints.POST_FB+'/'+ postId )
    commit('remove_success' ,{postId})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}



const createFacebookAdvertisment = async({commit},payload)=>{
    commit('request')
    try {
      const response = await API.post(ApiEndPoints.POST_FB_ADVERT, payload)
      commit('facebook_advert_create_success', response) 
      return response
    } catch (error) {
      commit('facebook_error')
      throw new Error(error.message)
    }
}

const insertFacebookAdvPost = async({commit},payload)=>{
  
    commit('request')
    try {
      const response = await API.post(ApiEndPoints.POST_FB_ADVERT+'/'+ payload.facebookAdId+'/advertisement', payload)
      commit('facebook_advert_post_assign_success', response) 
      return response
    } catch (error) {
      commit('facebook_error')
      if(error.message.startsWith('Invalid OAuth access token.') || error.message.startsWith('(#200) Provide valid app ID')) {
        commit('reset/facebook_reset_oauth', payload.socialId,{ root: true })
      }
      throw new Error(error.message)
    }
}

const removeFacebookAdvertisment = async({commit}, id)=>{  
  commit('request')
  try {
    await API.delete(ApiEndPoints.POST_FB_ADVERT+'/'+ id )
    commit('remove_success_advert' ,{id})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

/**
 * Instagram
 */
const instagramSearch = async({commit},payload)=>{
  const {socialId,data} = payload
  const params = data ? data : {}
  commit('request')
  try {
    
    const endpoint =  ApiEndPoints.SOCIAL_IG
    const response = await API.post(endpoint+'/'+ socialId+'/search/media', params)
    commit('facebook_search_success', response) 
    return response
  } catch (error) {
    
    console.log(error.message)
    if(
        error.message.startsWith('Invalid OAuth access token.') 
        || error.message.startsWith('(#200) Provide valid app ID') 
        || error.message.startsWith('The user must be an administrator, editor, or moderator') 
    ) {
      const socialName =  error.message.split('|')[2]
      commit('reset/facebook_reset_oauth', {socialId, socialName},{ root: true })
    }
    throw new Error(error.message)
  }
}


const instagramSearchStory = async({commit},payload)=>{
  const {socialId,data} = payload
  const params = data ? data : {}
  commit('request')
  try {
    
    const endpoint =  ApiEndPoints.SOCIAL_IG
    const response = await API.post(endpoint+'/'+ socialId+'/search/stories', params)
    commit('facebook_search_success', response) 
    return response
  } catch (error) {
    
    console.log(error.message)
    if(
        error.message.startsWith('Invalid OAuth access token.') 
        || error.message.startsWith('(#200) Provide valid app ID') 
        || error.message.startsWith('The user must be an administrator, editor, or moderator') 
    ) {
      const socialName =  error.message.split('|')[2]
      commit('reset/facebook_reset_oauth', {socialId, socialName},{ root: true })
    }
    throw new Error(error.message)
  }
}



const instagramAssignPost = async({commit},data)=>{
  commit('request')
  try {
    const response = await API.post(ApiEndPoints.POST_IG, data) 
    commit('facebook_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const instagramManualAssignStory = async({commit},data)=>{
  commit('request')
  try {
    const response = await API.post(ApiEndPoints.POST_IG+'/story', data) 
    commit('facebook_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const instagramMockUpdate = async({commit},data)=>{
  commit('request')
  try {
    const response = await API.patch(ApiEndPoints.POST_IG+'/'+data.postId+'/story', data) 
    commit('facebook_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const removeInstagramPost = async({commit}, postId)=>{
  commit('request')
  try {
    await API.delete( ApiEndPoints.POST_IG+'/'+ postId )
    commit('remove_success' ,{postId})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

const addInstagramAdvertismentToPost = async ({commit}, payload) => {
  console.log('addInstagramAdvertismentToPost',payload)
  commit('request')
  try {
    const response = await API.post(ApiEndPoints.SOCIAL_ADVERTISMENT + '/instagram', payload) 
    commit('instagram_advertisment_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const removeInstagramAdvertismentFromPost = async ({commit}, id) => {
  commit('request')
  try {
    await API.delete(ApiEndPoints.SOCIAL_ADVERTISMENT+'/instagram/'+ id )
    commit('remove_success_advert' ,{id})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}


export const actions = {
    index,
    facebookSearch,
    facebookAssignPost,
    removeFacebookPost,
    createFacebookAdvertisment,
    removeFacebookAdvertisment,
    insertFacebookAdvPost,
    instagramSearch,
    instagramSearchStory,
    instagramAssignPost,
    removeInstagramPost,
    addInstagramAdvertismentToPost,
    removeInstagramAdvertismentFromPost,
    instagramManualAssignStory,
    instagramMockUpdate
}