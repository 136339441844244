import { API } from '@/api/API'
import { ApiEndPoints } from '@/api/Endpoints'
import{useCapabilities}from '@/utils/Capabilities'



const facebookList = async ({commit})=>{
  commit('fb_request')
  try {
    //const response = await axios({url: ApiEndPoints.SOCIAL_FB, method: 'GET' })
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/facebook' : ApiEndPoints.SOCIAL_FB
    const {data, headers} = await API.get(endpoint)
   // const {data, headers} = await API.get(ApiEndPoints.SOCIAL_FB)
    const pagination = JSON.parse(headers['x-pagination'])
    //const {data} = response
    commit('fb_success' ,{ data, pagination })
  } catch (error) {
    commit('fb_error')
  }
}

const list = async ({commit}, payload={})=>{
  
  commit('social_request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/facebook' : ApiEndPoints.SOCIAL
    const {data, headers} = await API.get(endpoint, payload)
    const pagination = JSON.parse(headers['x-pagination'])
    commit('social_success' ,{ data, pagination })
    return data
  } catch (error) {
    commit('social_error')
  }
}

const bytag = async ({commit}, {tags, discriminator}) => {
  const map = tags.map(t => 'tag='+t.id)
  let query = map.length > 1 ? map.join('&') : map.join('')
  
  if(discriminator) {
    query+='&discriminator='+discriminator
  }
  commit('social_request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social' : ApiEndPoints.SOCIAL
    //const {data} = await API.get(ApiEndPoints.SOCIAL+'/?'+query)
    const {data} = await API.get(endpoint+'/?'+query)
    commit('social_by_tag_success' ,{ data })
    return data
  } catch (error) {
    commit('social_error')
  }
}

const clearList = async ({commit}) =>{
  commit('social_clear')
}

const filter = async ({commit, state}, filter) =>{ 
  const params = {}
  const {type, value} = filter
  commit('filter_request', {type, value})
  const activeFilters = state.activeFilters
  if(activeFilters.length === 0){
    
    commit('empty_filter', type)
    try {
      const { isPublisher, isConcessionaire} = useCapabilities()
      const usePublisherController = isPublisher()||isConcessionaire()
      const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social' : ApiEndPoints.SOCIAL
      
      const {data, headers} = await API.get(endpoint, {})
      //const {data, headers} = await API.get(ApiEndPoints.SOCIAL, {})
      const pagination = JSON.parse(headers['x-pagination'])
      commit('social_success' ,{ data, pagination })
      return data
    } catch (error) {
      commit('social_error')
      throw new Error(error.message)
    }
  } else {

    let url , search, discriminator
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social' : ApiEndPoints.SOCIAL
      
    switch (type) {
      case 'search':
        
          url = endpoint + '?' + type + '=' +value
          discriminator = activeFilters.find(f => f.type === 'discriminator')
          if(discriminator){
            url +='&discriminator='+discriminator.value
          } 
          
        break;
        case 'discriminator':
          if(value === 'Tutti'){
            url = endpoint
            search = activeFilters.find(f => f.type === 'search')
            if(search){
              url += '?search='+search.value
            }
          }else{
            url = endpoint + '?' + type + '=' +value
            search = activeFilters.find(f => f.type === 'search')
            if(search){
              url += '&search='+search.value
            }
          }
          
          
        break;
    
      default:
        break;
    }
    try {
      const {data, headers} = await API.get(url, params)
      const pagination = JSON.parse(headers['x-pagination'])
      commit('do_filter', {data, pagination,type})
      return data
    } catch (error) {
      commit('social_error')
      throw new Error(error.message)
    } 
  }
}


const sort = async ({commit}, sorting) =>{
  commit('do_sort', {sorting})
}

const updateSocialTags = async({commit}, {id,tags}) =>{
  commit('social_request')
  const query = tags.map(t => 'tags='+t.id).join('&')
  try {
    const {data} = await API.get(ApiEndPoints.SOCIAL+'/'+ id +'/tags?'+query)
    commit('social_tag_update_success' ,{ data })
    return data
  } catch (error) {
    commit('social_tag_update_error')
    throw new Error(error.message)
  }
}


const fetchfacebook = async({commit},{id}) => {
  commit('social_request')
  try {
    const data = await API.put(ApiEndPoints.SOCIAL_FB+'/'+ id)
    commit('social_fetch_success' ,{ data })
    return data
  } catch (error) {
    commit('social_fetch_error')
    const socialId = error.message.split('|')[1]
    if(socialId) {
      commit('reset/facebook_reset_oauth', socialId,{ root: true })
    }
    throw new Error(error.message)
  }
}
const fetchinstagram = async({commit},{id}) => {
  commit('social_request')
  try {
    const data = await API.put(ApiEndPoints.SOCIAL_IG+'/'+ id)
    commit('social_fetch_success' ,{ data })
    return data
  } catch (error) {
    commit('social_fetch_error')
    const socialId = error.message.split('|')[1]
    if(socialId) {
      commit('reset/facebook_reset_oauth', socialId,{ root: true })
    }
    throw new Error(error.message)
  }
}



const update = async({commit},{id,data})=>{
  commit('social_save_request')
  try {
    const response = await API.patch(ApiEndPoints.SOCIAL_FB+'/'+id, data)
    
    commit('update_success', {response}) 
    return response
  } catch (error) {
    commit('social_save_error')
    throw new Error(error.message)
  }
}
const updateIg = async({commit},{id,data})=>{
  commit('social_save_request')
  try {
    const response = await API.patch(ApiEndPoints.SOCIAL_IG+'/'+id, data)
    
    commit('update_success', {response}) 
    return response
  } catch (error) {
    commit('social_save_error')
    throw new Error(error.message)
  }
}
const remove = async({commit}, {id, discriminator})=>{
  const endpoint = discriminator === 'Facebook' ? ApiEndPoints.SOCIAL_FB : ApiEndPoints.SOCIAL_IG
  commit('social_request')
  try {
    await API.delete(endpoint+'/'+ id )
    commit('remove_success' ,{id}) 
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

const create = async({commit},{data})=>{
  commit('social_save_request')
  try {
    const response = await API.post(ApiEndPoints.SOCIAL_FB, data)
    commit('social_save_success', response) 
    return response
  } catch (error) {
    commit('social_save_error')
    throw new Error(error.message)
  }
}
const createIg = async({commit},{data})=>{
  commit('social_save_request')
  try {
    const response = await API.post(ApiEndPoints.SOCIAL_IG, data)
    commit('social_save_success', response) 
    return response
  } catch (error) {
    commit('social_save_error')
    throw new Error(error.message)
  }
}

const getSingleFb = async({commit}, id)=>{
  commit('social_request')
  try {
    const {data} = await API.get(ApiEndPoints.SOCIAL_FB+'/'+id)
    commit('single_get_success', {data}) 
    return data
  } catch (error) {
    commit('social_error')
    throw new Error(error.message)
  }
}


const resetFacebookTokens = async({commit}, token) =>{
  commit('social_request')
  
  try {
    
    await API.get(ApiEndPoints.SOCIAL_FB+'/accounts/accessToken/'+token+'/reset')
    commit('fb_reset_token_success') 
  } catch (error) {
    commit('fb_error')
    throw new Error(error.message)
  }
}



export const actions ={
  list,
  facebookList,
  bytag,
  clearList,
  create,
  createIg,
  filter,
  sort,
  updateSocialTags,
  fetchfacebook,
  fetchinstagram,
  update,
  updateIg,
  remove,
  getSingleFb,
  resetFacebookTokens
}