const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
import {store} from '@/store/store.js'
export const home ={
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        await store.dispatch('campaigns/index')
        //await store.dispatch('calendar/index')
        await store.dispatch('socialcampaigns/proposed')

        next();
      } catch(exception) {
        next(exception);
      }
    }
}