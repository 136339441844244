/**
 * Classico debounce, ritarda l'esecuzione di una funzione
 * @param {Function} func : la funzione da ritardare
 * @param {Number} wait : quanto deve aspettare prima di eseguire la funzione (ms)
 */
 export function debounce (func, wait) {
    
    let timeout = null
  
    return function () {
      const context = this
      const args = arguments
  
      const later = () => {
       
        func.apply(context, args)
      }
  
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }