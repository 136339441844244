
const facebook_reset_oauth = (state, {socialId, socialName}) => {
    state.resettingFacebook = true
    state.resettingFacebookId = socialId
    state.resettingFacebookName = socialName
}
const facebook_reset_oauth_close = (state) => {
    state.resettingFacebook = false
    state.resettingFacebookId = null
    state.resettingFacebookName = null
}
export  const mutations = {
    facebook_reset_oauth,
    facebook_reset_oauth_close
}