import { ApiEndPoints } from '@/api/Endpoints';
import { API } from '@/api/API';
import{useCapabilities}from '@/utils/Capabilities'

export const show = async (id) =>{
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/user' : ApiEndPoints.USERS
    const {data} = await API.get(endpoint+'/'+id)
    

    //const {data} = await API.get(ApiEndPoints.USERS+'/'+id)
    return data
}

export const list = async (params = {}, endpoint = ApiEndPoints.USERS) => {
    const {data, headers} = await API.get(endpoint, params)
    const pagination = JSON.parse(headers['x-pagination'])
    return {data, pagination}
}

export const apiUserFunction = {
    show,
    list
} 