import { mutations } from './mutations';
import { actions } from './actions';

export const userScopeModule = {
    state () {
        return {
          editing:false,  
          userScopes:[],
          available:null,
          filtered:null,
          columnsSizes:null,
          pagination:null,
          visitedPages:[],
          activeFilters:[],
        }
    },
    namespaced: true,
    getters: {
        scopes: state => state.userScopes,
        paged: state => {

            const currentPage = state.pagination.CurrentPage
            const pageSize = state.pagination.PageSize
            const offset = (currentPage * pageSize) - pageSize
            return state.userScopes.filter((tag, index) => (index >= offset && index <= offset + pageSize -1))
        },
        editing:state=>state.editing,
        available:state=>state.available,
        filtered:state=>state.filtered,
        activeFilters:state=>state.activeFilters,
        concessionaires:state=>state.userScopes.filter(s=>s.discriminator === 'Concessionaire'),
        publishers:state=>state.userScopes.filter(s=>s.discriminator === 'Publisher'),
        columnsSizes:state=>state.columnsSizes,
        pagination:state=>state.pagination
    },
    mutations,
    actions
}