<template>
    <label class="flex-container flex-col flex-0 mb8">
        <label class="bebas nomargin">User Scope</label>
        <select v-if="editing" class="noborder nopadding pl8" @change="onScopeSelect" name="userScopeId">
            <option v-if="!user.userScope.id" value="select" disabled  selected>select</option>
            <option 
                v-if="user.userScope && user.userScope.id" 
                :value="user.userScope.id" 
                selected
            >
                {{user.userScope.discriminator  + ' - ' + user.userScope.name}}
            </option>
            
            <option 
                v-for="scope in userScopes" 
                :key="scope.id" 
                :value="scope.id"
            >
                {{scope.discriminator + ' - ' + scope.name}}
            </option>
        </select>
        <select v-else class="noborder nopadding pl8" disabled>
            <option v-if="user.userScope && user.userScope.id" :value="user.userScope.id">{{user.userScope.discriminator  + ' - ' + user.userScope.name}}</option>
            <option v-else value="select" disabled selected>select</option>
        </select>
    </label>
</template>
<script>
import {reactive, computed} from 'vue'

export default {
    emits:['scope-select'],
    props:{
        scopes:{
            type:Array,
            default:()=>[]
        },
        selectedUser: {
            type:Object,
            default:()=>{}
        },
        editing:{
            type:Boolean,
            default:true
        }
    },
    setup(props, {emit}){
        
        const user = reactive(props.selectedUser)
        const userScopes = computed(()=> {
            return props.scopes.filter(scope => scope.discriminator === user.userScope.discriminator)
        })
        const onScopeSelect = (event)=>{
            emit('scope-select', event.target.value)
        }

        return{
            onScopeSelect,
            user,
            userScopes
        }
    }
}
</script>