import {store} from '@/store/store'
import {ref, reactive} from 'vue'
import {EventsHandler} from '@/utils/EventsHandler'

const tools = ref(null)
const card = ref(null)
const list = ref(null)
const calendarColumn = ref(null)
const latestColumn = ref(null)

const toolStyle = reactive({
   'min-width':'250px',
   'width': '30%',
   'max-width': '35%'
})

const cardStyle = reactive({
   'min-width':'300px',
   'width': '65%'
})

const listStyle = reactive({
    'min-width':'225px',
    'width': '30%',
    'max-width': '35%'
})
const calendarStyle = reactive({
    'min-width':'750px',
    'width': '64%',
    'max-width': '1228px'
})
const latestStyle = reactive({
    'min-width':'400px',
    'width': '33%',
    'max-width': '80%'
})

const totalSize = ref(null)

const onTotalSize= (event)=>{
    if(tools.value){
        cardStyle.width = event - list.value.$el.getBoundingClientRect().width - tools.value.$el.getBoundingClientRect().width + 'px'
    }else{
        if(list.value){
            cardStyle.width = event - list.value.$el.getBoundingClientRect().width + 'px' 
        } 
    }
    if(calendarColumn.value && latestColumn.value){
        latestStyle.width = event - calendarColumn.value.$el.getBoundingClientRect().width + 'px' 
    }
    totalSize.value = event
}

const homeColumnResize = (event) =>{
    const {diff, index, currentWidth} = event
    
    let newColumnWidth = currentWidth + diff 
    let cardW = calendarColumn.value.$el.getBoundingClientRect().width
    if(index === 0){
        calendarStyle.width =  newColumnWidth  + 'px'
        latestStyle.width = totalSize.value - newColumnWidth + 'px'
    }else{
        calendarStyle.width = totalSize.value - newColumnWidth - cardW + 'px'
        latestStyle.width = newColumnWidth + 'px'
    }
    EventsHandler.publish('resize-calendar')
}

const usersColumnResize = (event)=>{
    const {diff, index, currentWidth} = event
    let newColumnWidth = currentWidth + diff 
    let toolsWidth = tools.value.$el.getBoundingClientRect().width
    let listWidth = list.value.$el.getBoundingClientRect().width
    const hasCard = card.value
    
    if(index ===0){

        if(hasCard) cardStyle.width = totalSize.value - newColumnWidth - listWidth + 'px'
        toolStyle.width = newColumnWidth + 'px'
    }else if(index === 1){
        if(hasCard) cardStyle.width = totalSize.value - newColumnWidth - toolsWidth + 'px'
        listStyle.width = newColumnWidth + 'px'
    }else{
        
        cardStyle.width = newColumnWidth + 'px'
    }
}
const campaignColumnResize = (event)=>{
    const {diff, index, currentWidth} = event
    let newColumnWidth = currentWidth + diff  
    let listWidth = list.value.$el.getBoundingClientRect().width
    const hasCard = card.value
    if(index ===0){
        if(hasCard)
            cardStyle.width = totalSize.value - newColumnWidth  + 'px'
        listStyle.width = newColumnWidth + 'px'
    }else if(index === 1){
        if(hasCard) {
            cardStyle.width = totalSize.value - newColumnWidth - listWidth + 'px'
        }
        listStyle.width = newColumnWidth + 'px'
    }
}

const tagsColumnResize = (event)=>{
    const {diff, index, currentWidth} = event
    let newColumnWidth = currentWidth + diff  
    let listWidth = list.value.$el.getBoundingClientRect().width
    const hasCard = card.value
    if(index ===0){

        if(hasCard)
            cardStyle.width = totalSize.value - newColumnWidth  + 'px'
        listStyle.width = newColumnWidth + 'px'
    }else if(index === 1){
        if(hasCard) cardStyle.width = totalSize.value - newColumnWidth - listWidth + 'px'
        listStyle.width = newColumnWidth + 'px'
    }
}

const resetResizerValues =(storePath, id=undefined)=> {
    
    const sizes = store.getters[storePath+'/columnsSizes']
    
    if(sizes){
       
        toolStyle['width'] = sizes['toolsW'] ? sizes['toolsW'] : '30%'
        listStyle['width'] = sizes['listW'] ? sizes['listW'] : '30%'
        if(id && sizes['cardW']){
            cardStyle['width'] = sizes['cardW']
        }
        
    }else{
        
        toolStyle['width'] = '30%'
        listStyle['width'] = '30%'
        if(id)
            cardStyle['width'] = '70%'
    }
   
   
}

const disableElementUserSelect = ()=>{
    Array.from(document.querySelectorAll('*')).forEach(el => {
        el.classList.add('unselectable')
    })
}
const enableElementUserSelect = ()=>{
    Array.from(document.querySelectorAll('*')).forEach(el => {
        el.classList.remove('unselectable')
    })
}

export {
    tools,
    toolStyle,
    card,
    cardStyle,
    list,
    listStyle,
    onTotalSize,
    totalSize,
    usersColumnResize,
    campaignColumnResize,
    tagsColumnResize,
    resetResizerValues,
    disableElementUserSelect,
    enableElementUserSelect,
    homeColumnResize,
    calendarStyle,
    latestStyle,
    latestColumn,
    calendarColumn
}