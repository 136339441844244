import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'
import {useCapabilities} from '@/utils/Capabilities'

const index = async({commit}, payload)=>{

  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/campaign' : ApiEndPoints.CAMPAIGNS
    const {data, headers} = await API.get(endpoint, payload)
   

    //const {data, headers} = await API.get(ApiEndPoints.CAMPAIGNS,payload)
    const pagination = JSON.parse(headers['x-pagination'])
    commit('get_success' ,{ data, pagination })
    return data
  } catch (error) {
    commit('get_error')
    throw new Error(error.message)
  }
}

const bytag = async ({commit}, {tags}) => {
  
  commit('request')
  const map = tags.map(t => 'tag='+t.id)
  
  const query = map.length > 1 ? map.join('&') : map.join('')
  
  try {
    const {data} = await API.get(ApiEndPoints.CAMPAIGNS+'/?'+query)
    commit('by_tag_success' ,{ data })
    return data
  } catch (error) {
    commit('error')
    throw new Error(error.message)
  }
}

const clearList = async ({commit}) =>{
  commit('clear')

}

/**
 * Salva un nuovo utente
 */
const create = async ({commit},{data}) =>{
  commit('save_request')
  try {
    const response = await API.post(ApiEndPoints.CAMPAIGNS, data)
    commit('save_success', response) 
    return response
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}



const syncTags = async({commit}, {id,tags}) =>{
  commit('request')
  const storetags = tags.map(t => t)
  const query = tags.map(t => 'tags='+t.tagId).join('&')
  try {
    await API.get(ApiEndPoints.CAMPAIGNS+'/'+ id +'/tags?'+query)
    commit('tag_update_success' ,{id,storetags})
    return tags
  } catch (error) {
    commit('tag_update_error')
    throw new Error(error.message)
  }
}


const addSocial = async({commit}, {campaignId,socialId}) => {
  commit('request')
  
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/social-campaign/social/${socialId}/campaign/${campaignId}`
      : ApiEndPoints.SOCIAL_CAMPAIGNS+`/social/${socialId}/campaign/${campaignId}`
    const response = await API.post(endpoint, {})
    //const response = await API.post(ApiEndPoints.SOCIAL_CAMPAIGNS+`/social/${socialId}/campaign/${campaignId}`, {})
                
    commit('social_sync_success' ,response)
    return response
  } catch (error) {
    commit('social_sync_error', error)
    throw new Error(error.message)
  }
}
const removeSocial = async({commit}, {campaignId,socialId}) => {
  commit('request')
  
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/social-campaign/social/${socialId}/campaign/${campaignId}`
      : ApiEndPoints.SOCIAL_CAMPAIGNS+`/social/${socialId}/campaign/${campaignId}`

    await API.delete(endpoint, {})
    //await API.delete(ApiEndPoints.SOCIAL_CAMPAIGNS+`/social/${socialId}/campaign/${campaignId}`, {})
                               
    commit('social_remove_success' ,{campaignId,socialId})
    return {campaignId,socialId}
  } catch (error) {
    commit('social_sync_error', error)
    throw new Error(error.message)
  }
}


const remove = async({commit}, id)=>{
    commit('request')
    try {
      await API.delete(ApiEndPoints.CAMPAIGNS+'/'+ id )
      commit('remove_success' ,{id})
      
    } catch (error) {
      commit('remove_error')
      throw new Error(error.message)
    } 
}

const update = async({commit},{id,data, socialCampaigns, postCampaigns, campaignTags})=>{
  commit('save_request')
  try {
    const response = await API.put(ApiEndPoints.CAMPAIGNS+'/'+id, data)
    commit('update_success', {
      ...response, 
      socialCampaigns:socialCampaigns, 
      postCampaigns:postCampaigns, 
      campaignTags:campaignTags
    }) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}



const filter = async ({commit}, filter) =>{
  commit('request')
  const params = {}
  
  if(filter.value !== ''){
    params[filter.type] = filter.value
    try {

      const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/campaign`
      : ApiEndPoints.CAMPAIGNS


      const {data, headers} = await API.get(endpoint, params)
      //const {data, headers} = await API.get(ApiEndPoints.CAMPAIGNS, params)
      const pagination = JSON.parse(headers['x-pagination'])
      commit('do_filter', {data, pagination})
      return data
    } catch (error) {
      commit('get_error')
      throw new Error(error.message)
    }
  } else {
    commit('empty_filter')
    try {
      const { isPublisher, isConcessionaire} = useCapabilities()
      const usePublisherController = isPublisher()||isConcessionaire()
      const endpoint = usePublisherController ? 
        ApiEndPoints.PUBLISHERS+`/campaign`
        : ApiEndPoints.CAMPAIGNS
      const {data, headers} = await API.get(endpoint, {})
      //const {data, headers} = await API.get(ApiEndPoints.CAMPAIGNS, {})
      const pagination = JSON.parse(headers['x-pagination'])
      commit('get_success' ,{ data, pagination })
      return data
    } catch (error) {
      commit('get_error')
      throw new Error(error.message)
    }
  }
  
  
}

const sort = async ({commit}, sorting) =>{
  commit('do_sort', {sorting})
}


const getSingle = async({commit}, id)=>{
  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/campaign/${id}`
      : ApiEndPoints.CAMPAIGNS+'/'+id

    const {data} = await API.get(endpoint)
    //const {data} = await API.get(ApiEndPoints.CAMPAIGNS+'/'+id)
    commit('single_get_success', {data}) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}

const authorize = async({commit}, data) => {
  commit('request')
  try {
    await API.patch(ApiEndPoints.SOCIAL_CAMPAIGNS+'/social/'+data.socialId +'/campaign/'+ data.campaignId + '/authorize', {authorized:data.authorized})
    commit('social_campaign_authorized', data) 
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}
const accept = async({commit}, data) => {
  
  commit('request')
  try {
    await API.patch(ApiEndPoints.SOCIAL_CAMPAIGNS+'/social/'+data.socialId +'/campaign/'+ data.campaignId + '/accept', {
      accepted:data.accepted
    })
    commit('social_campaign_accepted', data) 
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}

const monetize = async({commit}, id) => {
  
  commit('request')
  try {
    const {data}  = await API.get(ApiEndPoints.CAMPAIGNS+'/'+id + '/monetize')
    commit('monetize_success', data) 
  } catch (error) {
    commit('save_error')
    
    const socialId = error.message.split('|')[1]
    const socialName = error.message.split('|')[2]
    if(socialId) {
      commit('reset/facebook_reset_oauth', {socialId, socialName},{ root: true })
    }
    throw new Error(error.message)
  }
}


const getApprovedSocialsForCampaign = async ({commit}, id ) =>{
 
  commit('request')
  try {
    
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social-campaign' : ApiEndPoints.SOCIAL_CAMPAIGNS
    const {data} = await API.get(endpoint +'/campaign/'+id + '/socials')
    
    //const {data}  = await API.get(ApiEndPoints.SOCIAL_CAMPAIGNS+'/campaign/'+id + '/socials')
    commit('approved_socials_success', data) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}


const updateSocialPrices = async ({commit}, {socialId, campaignId, data}) =>{
  
  commit('request')
  try {
    await API.patch(ApiEndPoints.SOCIAL_CAMPAIGNS+'/social/'+socialId +'/campaign/'+ campaignId , data)
    commit('social_campaign_price_updated', {socialId, campaignId, data}) 
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}


const getAnalyticsForCampaign = async ({commit}, id ) =>{
  commit('analytics_socials_request')
  const {isAdvertiser,isPublisher, isConcessionaire} = useCapabilities()
  const usePublisherController = isPublisher()||isConcessionaire()
  let endpoint = ApiEndPoints.SOCIAL_CAMPAIGNS+'/campaign/'+id + '/socials' 
  if(usePublisherController){
    endpoint =  ApiEndPoints.PUBLISHERS+'/social-campaign/campaign/'+id + '/socials'  
  } else {
    if(isAdvertiser()){
      endpoint += '/analytics'
    }
  }
  
  try {
    const {data}  = await API.get(endpoint)
    commit('analytics_socials_success', data) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}
const publishDate = async({commit}, payload)=>{
  commit('request')
  try {
    ///api/social-campaign/social/{socialId}/campaign/{campaignId}/event
      const data = await API.post(ApiEndPoints.SOCIAL_CAMPAIGNS+'/social/'+payload.sharedExtendedProperties.socialId +'/campaign/'+payload.sharedExtendedProperties.campaignId +'/event', payload)
      commit('publishdate_success' ,{ data })
      return data
  } catch (error) {
      commit('publishdate_error')
      throw new Error(error.message)
  }
}

const deleteEvent = async({commit}, payload) => {
  commit('request')
  try{
    const {data} = await API.delete(ApiEndPoints.SOCIAL_CAMPAIGNS+`/social/${payload.socialId}/campaign/${payload.campaignId}/event/${payload.id}`)
    commit('delete_event_success' )
    return data
  }
  catch (error) {
      commit('delete_event_error')
      throw new Error(error.message)
  }
}



export const actions = {
  index,
  bytag,
  clearList,
  syncTags,
  create,
  remove,
  update,
  filter,
  sort,
  addSocial,
  removeSocial,
  getSingle,
  authorize,
  monetize,
  accept,
  getApprovedSocialsForCampaign,
  updateSocialPrices,
  getAnalyticsForCampaign,
  publishDate,
  deleteEvent,
}