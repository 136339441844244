import { mutations } from './mutations';
import { actions } from './actions';



export const postModule = {
    state () {
        return {
          posts:[],
          adverts:[],
          searchResult:[],
          filtered:null,
          columnsSizes:null
        }
    },
    namespaced: true,
    getters: {
        adverts: state => state.adverts,
        posts: state => state.posts,
        columnsSizes:state=>state.columnsSizes,
        filtered:state=>state.filtered,
        searchResult:state=>state.searchResult
    },
    mutations,
    actions
}