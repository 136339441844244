export const DO_LOGIN = 'auth/login'
export const DO_LOGOUT = 'auth/dologout'
export const IS_LOGGED = 'auth/isLoggedIn'
export const TOKEN = 'auth/token'
export const ME = 'auth/me'
export const REAL_ROLE = 'auth/realRole'
export const CURRENT_ROLE = 'auth/currentRole'
export const SWITCH_ROLE = 'auth/switchRole'
export const FORGOT_PASSWORD = 'auth/forgotPassword'
export const UNDO_FORGOT_PASSWORD = 'auth/undoForgotPassword'
export const HAS_FORGOT_PASSWORD = 'auth/pwdForgot'
export const RESET_PASSWORD = 'auth/resetPassword'

export const USERS = 'user/users'
export const USER_SCOPES = 'userscope/userScopes'
export const GET_USER_SCOPES = 'userscope/scopes'

export const CAMPAIGNS = 'campaigns/campaigns'
export const GET_CAMPAIGNS = 'campaigns/index'


export const TAGS = 'tags/tags'
export const GET_TAGS = 'tags/getTags'


export const SOCIAL_FB = 'social/facebook'
export const SOCIAL_FB_LIST = 'social/facebookList'
export const SOCIAL_LIST = 'social/social'

export const MESSAGE = 'messages/message'
export const MESSAGE_TITLE = 'messages/title'
export const MESSAGE_SHOW = 'messages/show'
export const MESSAGE_CLOSE = 'messages/close'