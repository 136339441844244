import { mutations } from './mutations';
import { actions } from './actions';

export const resetModule = {
    state () {
        return {
          resettingFacebook: false,
          resettingFacebookId:null,
          resettingFacebookName:null
        }
    },
    namespaced: true,
    getters: {
        resettingFacebook: state => !!state.resettingFacebook,
        resettingFacebookId: state => state.resettingFacebookId,
        resettingFacebookName: state => state.resettingFacebookName
    },
    mutations,
    actions
}