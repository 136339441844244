<template>
    <label :class="className">
        <div class="flex-container heading-and-error flex-start-align no-grow ">
            <h3 :class="labelClassName">{{label}}</h3>
            <ErrorMessage v-if="editing" :validationStatus="vuel.text" />
        </div>
        
        <input
            v-if="editing" 
            required 
            v-model="text" 
            type="text" 
            :name="name"
            @input="()=>$emit('input', {text:text , name:name})"
            :placeholder="placeholder"
            class="noborder nopadding pl8"
            @blur="handleBlur('text')"
        />
        <input v-else type="text" :placeholder="placeholder"  class="noborder nopadding pl8" disabled :value="value">
    </label>
   
</template>
<script>
import { ref} from 'vue'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators"
import ErrorMessage from "./ErrorMessage";

export default {
    emits:['input'],
    props:{
        name:{
            type: String,
            default:''
        },
        label:{
            type: String,
            default:''
        },
        placeholder:{
            type:String,
            default:'insert value here'
        },
        value:{
            type:[String, Number],
            default:''
        },
        editing:{
            type:Boolean,
            default:false
        },
        className:{
            type:String,
            default:'flex-container flex-col flex-0 mb8'
        },
        labelClassName:{
            type:String,
            default:'bebas nomargin'
        },

    },
    components:{
        ErrorMessage
    },
    setup(props){
        
        const text = ref(props.value);
        const rules = { text: { required }}
        const vuel = useVuelidate(rules,{ text })
        const handleBlur = (key) =>{
            vuel.value[key].$dirty = true;
        }
        
        return{
            text,
            vuel,
            handleBlur
        }
    }
}
</script>