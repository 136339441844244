<template>
  <div v-if="validationStatus.$error" class="error-message">
    <p v-for="$error in validationStatus.$errors" :key="$error.$property" class="nomargin">
      {{ $error.$message }}
    </p>
  </div>
</template>
<script>
export default {
  name: "ErrorMessage",
  props: {
    validationStatus: {
      type: Object,
      required: true
    },
  }
  
};
</script>
