<template>
    <li class="layout-list-item">
        <router-link
            v-if="mode === 'nav'" 
            :to="'/user/'+ user.id" 
            :class="selected ? 'layout-list-item-btn flex-container pl16 pr16 selected' : 'layout-list-item-btn flex-container pl16 pr16'"
        >
            <div 
                v-if="user.avatar" 
                class="layout-list-icon user-icon"
                :style="`background-image:url('${API_BASE +'/file/'+ user.avatar.id}')`"
            >
            </div>
            <div v-else class="layout-list-icon user-icon"></div>
            <div class="flex-container flex-col layout-list-item-details-container">
                <p class="pl8 layout-list-item-title nomargin">{{user.userName}}</p>
                <div class="flex-container">
                    <p class="pl8 layout-list-item-subtitle nomargin f12">
                        <span v-if="user.userScope !== null">{{user.roles[0]}} : {{user.userScope.name}} </span>
                        <span v-else><i>{{user.roles[0]}}</i>  </span>
                    </p>
                </div>
            </div>
        </router-link>
        <a
            v-else 
            :class="selected ? 'layout-list-item-btn flex-container pl16 pr16 selected' : 'layout-list-item-btn flex-container pl16 pr16'"
        >
            <div 
                v-if="user.avatar" 
                class="layout-list-icon user-icon"
                :style="`background-image:url('${API_BASE +'/file/'+ user.avatar.id}')`"
            >
            </div>
            <div v-else class="layout-list-icon user-icon"></div>
            <div class="flex-container flex-col layout-list-item-details-container">
                <p class="pl8 layout-list-item-title nomargin">{{user.userName}}</p>
                <div class="flex-container">
                    <p class="pl8 layout-list-item-subtitle nomargin f12">
                        <span v-if="user.userScope !== null">{{user.roles[0]}} : {{user.userScope.name}} </span>
                        <span v-else><i>{{user.roles[0]}}</i>  </span>
                    </p>
                </div>
            </div>
        </a>
        
    </li>
</template>
<script>

import {computed} from 'vue'

import { API_BASE } from '@/api/Endpoints'

export default {
    emits:['list-select'],
    props:{
        user:{
            type:Object,
            default:()=>{}
        },
        selectedId:{
            type:String,
            default:''
        },
        mode:{
            type:String,
            default:'nav'
        }
    },
    setup( props){
        
        const selected = computed(()=>{
            return props.user.id === props.selectedId 
        }) 
        return{ 
           selected,
           API_BASE
        }
    }
    
}
</script>