const message = ({commit}, msg)=>{
    commit('message_show', {msg})
}
const close = ({commit})=>{
    commit('message_hide')
}

export const actions = {
    message,
    close
}