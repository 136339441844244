
/**
 * 
 * User Mutations
 */


/**
 * Sto richiedendo dati relativi agli utenti
 * @param {*} state 
 */ 
const user_request=(state)=>{
  state.loading = true
}
/**
 * Ho ricevuto id dati degli utenti
 * @param {*} state 
 * @param {*} data 
 */
const users_success=(state, {data, pagination})=>{
  state.loading = false
 /*  state.users = data
  state.pagination = pagination */

  const {CurrentPage} = pagination 
  //prima navigazione
  if(!state.pagination){
    state.pagination = pagination
    state.users = data
    state.visitedPages.push(CurrentPage)
  } else {
     state.pagination = pagination
    ///se non ho mai visto la paginata -> aggiungo i nuovi dati
    if(!state.visitedPages.find(p => p===CurrentPage)){
      state.visitedPages.push(CurrentPage)
       data.forEach(u =>{
        state.users.push(u)
      }) 

    }else{
      ////altrimenti eseguo l'update sulla porzione di store
      data.forEach((user, index) =>{
        const toReplace = state.users.find(u =>u.id === user.id)
        if(toReplace){
          Object.assign(toReplace, user)
        }else {
          state.users[index] = user
        }
      })
    }
    
    state.pagination = pagination
  }

}
/**
 * Errore nella richiesta dei dati
 * @param {*} state 
 */
const users_error = (state)=>{
  state.loading = false
  
}

const user_selected_role =(state, {role})=>{ 
  state.currentRole = role
}

const users_clear =(state)=>{
  state.users = []
}

const empty_filter = (state, type)=>{
  const indx = state.activeFilters.findIndex(f => f.type === type)
  if(indx !== -1){
    state.activeFilters = state.activeFilters.filter(f => f.type !== type)
  }
  if(state.activeFilters.length ===0){
    state.filtered = null
  }
  
}
const filter_request = (state, {type, value})=>{
    state.loading = true
    const indx = state.activeFilters.findIndex(f => f.type === type) 
    const emptySearch = type === 'search' && value === ''
    const allRoles = type === 'roles' && value === 'Tutti'
    
    if(indx === -1){
        if(type === 'roles' && !allRoles){
          state.activeFilters.push({type:type, value:value})
        }
        if(type === 'search' && !emptySearch){
          state.activeFilters.push({type:type, value:value})
        }   
        
    }else {
      if(type === 'search'){
        if(emptySearch){
          state.activeFilters = state.activeFilters.filter(f => f.type !== 'search')
        } else {
          state.activeFilters[indx]['value'] = value
        }
      }
      if(type === 'roles'){
        if(allRoles){
          state.activeFilters = state.activeFilters.filter(f => f.type !== 'roles')
        } else{
          state.activeFilters[indx]['value'] = value
        }
      }
    }
}
const do_filter =(state, {data, pagination}) => {
    state.filtered = data
    state.pagination = pagination
  }
  const clear_filters =(state)=>{
    state.filtered=null
    state.activeFilters=[]
  }
const update_request=(state)=>{
  state.loading = true
}
const update_success=(state, data)=>{
  state.loading = false
  const updated = state.users.find(user=> user.id===data.id)
  if(updated)
    Object.assign(updated, data)
}
const update_error = (state)=>{
  
  state.loading = false
  
}

const save_request=(state)=>{
  state.loading = true
}
const save_success=(state, data)=>{
  state.loading = false
  state.users.unshift(data)
}
const save_error = (state)=>{
  state.loading = false
}

const remove_error =(state)=>{
  state.loading = false
}
const remove_success =(state, user)=>{
  state.loading = false
  const users = state.users.filter(u => u.id !== user.id)
  state.users = users
}
const savecolumnsizes = (state, sizes)=>{
  state.columnsSizes = sizes
}

const upload_success = (state, {response})=>{
  
  state.loading = false
  const updated = state.users.find(user=> user.id===response.userId)
  if(updated){
    const avatar = updated.avatar
    if(avatar){
      
      avatar.id = response.id
      avatar.userId = response.userId
    }else{
      
      updated.avatar = {
        id:response.id,
        userId:response.userId
      }
    }
  }
  
}

const do_sort =(state, {sorting}) => {
  
  switch(sorting.type){
    case 'Nome asc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => a.userName.localeCompare(b.userName))
      }else{
        state.users = state.users.sort((a, b) => a.userName.localeCompare(b.userName))
      }
      
    break
    case 'Nome desc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => b.userName.localeCompare(a.userName))
      }else{
        state.users = state.users.sort((a, b) => b.userName.localeCompare(a.userName))
      }
    break
    case 'data creazione asc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }else{
        state.users = state.users.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      
    break
    case 'data creazione desc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }else{
        state.users = state.users.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }
    break
  }
  
}

const single_get_success = (state, {data})=>{
  const toReplace = state.users.find(u =>u.id === data.id) 
  if(toReplace)
    Object.assign(toReplace, data);
  else {
    state.users.unshift(data)
  }
}
  
export  const mutations = {
    user_request,
    users_success,
    users_error,
    user_selected_role,
    users_clear,
    //user_edit,
    do_filter,
    do_sort,
    update_request,
    update_success,
    update_error,
    save_request,
    save_success,
    save_error,
    remove_error,
    remove_success,
    savecolumnsizes,
    upload_success,
    single_get_success,
    empty_filter,
    filter_request,
    clear_filters
  }