import axios from 'axios'


export const API = {
  get: async (url, params={}) => {
      try {
          const response = await axios({url: url, method: 'GET',params:params })
          const { data , headers } = response
          return { data , headers }
        } catch (error) {
        
        if(error?.response?.data.code === 190){
          
          throw new Error(error?.response?.data.code)
        } else{
          const msg = handleErrorMessage(error)
          throw new Error(msg)
        }
      }
  },

  post: async (url, postData) => {
      try {
        const response = await axios({url: url, data: postData, method: 'POST' })
        
        const{data} = response

        return data
      } catch (error) {
        if(error?.response?.data.code === 190 ){
          
          throw new Error(error?.response?.data.code)
        } else{
         
          const msg = handleErrorMessage(error)
          throw new Error(msg)
        }
        
        
      }
  },
  put: async (url, postData) => {
    try {
      const response = await axios({url: url, data: postData, method: 'PUT' })
      const{data} = response
      return data
    } catch (error) {
      
      const msg = handleErrorMessage(error)
       throw new Error(msg)
    }
  },
  patch: async (url, postData) => {
    try {
      const response = await axios({url: url, data: postData, method: 'PATCH' })
      const{data} = response
      return data
    } catch (error) {
      const msg = handleErrorMessage(error)
      throw new Error(msg)
    }
  },
  delete:async(url)=>{
    
    try {
      const response = await axios({url: url, method: 'DELETE' })
      const{data} = response
      return data
    } catch (error) {
      const msg = handleErrorMessage(error)
      throw new Error(msg)
    }
  }
}


function handleErrorMessage(error){

  
  if (error.response) {
    
    const errorResponseData = error.response.data
    
    try {
      
      if(errorResponseData?.errors?.map){
        
        return  errorResponseData.errors.map(err => err.description).join()
      } else if(errorResponseData?.errors?.advertisement?.map){
       
        return  errorResponseData.errors.advertisement.join("")
      }  else if(errorResponseData?.errors?.social?.map){
        
        return  errorResponseData.errors.social.join("")
      } else {

        
        if(errorResponseData.error_user_msg){
          return errorResponseData.error_user_msg 
        }
        if(errorResponseData.type === 'OAuthException'){
          return errorResponseData.type
        }
        

        if(errorResponseData.error){
         
          let msg = errorResponseData.error.message
          if(errorResponseData.social){
            msg += '|'+errorResponseData.social.socialId + '|' + errorResponseData.social.name
          }
          return msg
        } else if(errorResponseData.title) {
          

          let message = errorResponseData.title
          if(errorResponseData.errors){
            
            message += `<br>`
            let errors = Object.keys(errorResponseData.errors)
                          .map(key =>errorResponseData.errors[key][0]) 
                          .map(err => `<p>${err.social.name + ' : '  + err.error.message}<p>`)
                          .join('')
              message += `<br>${errors}`
          } 
          


          return message
        } else {
         
          return errorResponseData.code
        }
      }
    } catch (er) {
      
      return  error.message
    }
   
  } else{
    return  error.message
  }
}