import { mutations } from './mutations';
import { actions } from './actions';



export const socialCampaignsModule = {
    state () {
        return {
          proposed:[],
          pagination:null,
          single:null,
          visitedPages:[]
        }
    },
    namespaced: true,
    getters: {
        proposed: state => state.proposed,
        paged: state => {

            const currentPage = state.pagination.CurrentPage
            const pageSize = state.pagination.PageSize
            const offset = (currentPage * pageSize) - pageSize
            return state.proposed.filter((campaign, index) => (index >= offset && index <= offset + pageSize -1))
        },
        pagination:state=>state.pagination
    },
    mutations,
    actions
}