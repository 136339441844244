import{
    USER_ROLE_SUPER,
    USER_ROLE_ADVERTISER,
    USER_ROLE_CONCESSIONAIRE_ADMIN,
    USER_ROLE_CONCESSIONAIRE_USER,
    USER_ROLE_PUBLISHER_ADMIN,
    USER_ROLE_PUBLISHER_USER
  } from '@/utils/RolesAndPolicies'

export const getMainGridClassname =(me, bigMenu)=>{
    let className =  'grid-container'
      if(!bigMenu.value) {
        className+= ' mini-menu'
      }
      if(me.value){
          switch(me.value.roles[0]){
            case USER_ROLE_SUPER:
                className += ' super-layout'
                break;
            case USER_ROLE_CONCESSIONAIRE_ADMIN:
            case USER_ROLE_CONCESSIONAIRE_USER:
                className += ' conc-layout'
                break;
            case USER_ROLE_PUBLISHER_ADMIN:
            case USER_ROLE_PUBLISHER_USER:
                className += ' pub-layout'
                break;
            case USER_ROLE_ADVERTISER:
                className += ' adv-layout'
                break;
            default:
                break;
        }
        
      }
      return className
}