import { ApiEndPoints } from '../../api/Endpoints';
import { API } from '@/api/API';
import {useCapabilities} from '@/utils/Capabilities'
import { 
  USER_ROLE_ADMIN, 
  USER_ROLE_ADVERTISER,
  USER_ROLE_CONCESSIONAIRE_ADMIN,
  USER_ROLE_CONCESSIONAIRE_USER,
  USER_ROLE_PUBLISHER_ADMIN,
  USER_ROLE_PUBLISHER_USER ,
  USER_SCOPE_PUBLISHER_DISCRIMINATOR,
  USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR
} from '@/utils/RolesAndPolicies';


/**
 * Richiede la lista degli utenti
 */
const users = async({commit}, payload={})=>{
  commit('user_request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
   
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/user/`
      : ApiEndPoints.USERS
    
    const {data, headers} = await API.get(endpoint, payload)     
    
    const pagination = JSON.parse(headers['x-pagination'])
    commit('users_success' ,{ data, pagination })
  } catch (error) {
    commit('users_error')
    throw new Error(error.message)

  }
}

/**
 * Richiede la lista degli user-scopes
 */
const userScopes = async({commit},payload={})=>{
  commit('userscope_request')
  try {
    const {data, headers} = await API.get(ApiEndPoints.USER_SCOPE, payload)
    const pagination = JSON.parse(headers['x-pagination'])
    commit('userscope_success' ,{ data, pagination })
  } catch (error) {
    commit('userscope_error')
  }
}


/**
 * Richiede il cambio di ruolo (x il mock)
 * funzionalità tutta da scrivere
 */
const changeUserRole = async ({  commit }, role) =>{
  commit('user_selected_role', role)
  commit('userscope/userscope_selected_role', role,{ root: true })
  //commit('user_selected_role', role)

}

/**
 * Filtra la lista degli utenti
 * 
 */
const filter = async ({commit, state}, filter) =>{
  const params = {}
  const {type, value} = filter
  commit('filter_request', {type, value})
  const activeFilters = state.activeFilters
  if(activeFilters.length === 0){
    commit('empty_filter', type)
    try {
      const { isPublisher, isConcessionaire} = useCapabilities()
      const usePublisherController = isPublisher()||isConcessionaire()
    
      const endpoint = usePublisherController ? 
        ApiEndPoints.PUBLISHERS+`/user/`
        : ApiEndPoints.USERS
      const {data, headers} = await API.get(endpoint, {})
      const pagination = JSON.parse(headers['x-pagination'])
      commit('users_success' ,{ data, pagination })
      return data
    } catch (error) {
      commit('users_error')
      throw new Error(error.message)
    }
  } else {
    const { isPublisher, isConcessionaire} = useCapabilities()
      const usePublisherController = isPublisher()||isConcessionaire()
    
      const endpoint = usePublisherController ? 
        ApiEndPoints.PUBLISHERS+`/user/`
        : ApiEndPoints.USERS
      
    let url= endpoint , search, role
    switch (type) {
      case 'search':
          
          url = endpoint + '?' + type + '=' +value
          role = activeFilters.find(f => f.type === 'roles')
          if(role){
            if(role.value === USER_ROLE_ADMIN || role.value === USER_ROLE_ADVERTISER){
              url +='&roles='+role.value
            }else if(value === USER_SCOPE_PUBLISHER_DISCRIMINATOR){
              url +='&roles='+ USER_ROLE_PUBLISHER_ADMIN +'&roles='+ USER_ROLE_PUBLISHER_USER
            }else if(value === USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR){
              url +='&roles='+USER_ROLE_CONCESSIONAIRE_ADMIN+'&roles='+USER_ROLE_CONCESSIONAIRE_USER
            }
          } 
          
        break;
        case 'roles':
          if(value === USER_ROLE_ADMIN || value === USER_ROLE_ADVERTISER){
            url = endpoint+'?roles='+value
          }else if(value === USER_SCOPE_PUBLISHER_DISCRIMINATOR){
            url = endpoint+'?roles='+USER_ROLE_PUBLISHER_ADMIN+'&roles='+USER_ROLE_PUBLISHER_USER
          }else if(value === USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR){
            url = endpoint+'?roles='+USER_ROLE_CONCESSIONAIRE_ADMIN+'&roles='+USER_ROLE_CONCESSIONAIRE_USER
          }
          search = activeFilters.find(f => f.type === 'search')
          if(search){
            if(value !== 'Tutti'){
              url += '&search='+search.value
            }else{
              url += '?search='+search.value
            }
          }
          
        break;
    
      default:
        break;
    }
    
    try {
      const {data, headers} = await API.get(url, params)
      const pagination = JSON.parse(headers['x-pagination'])
      
      commit('do_filter', {data, pagination,type})
      return data
    } catch (error) {
      commit('users_error')
      throw new Error(error.message)
    } 
  }
}
 


const sort = async ({commit}, sorting) =>{
  commit('do_sort', {sorting})
}

/**
 * Esegue l'update di un user
 */
const update = async ({commit},{id,data}) =>{
  commit('update_request')
  try {
      const response = await API.patch(ApiEndPoints.USERS+'/'+id, data)
      commit('update_success', response)
  } catch (error) {
      commit('update_error')
      throw new Error(error.message)
  }
}

/**
 * Salva un nuovo utente
 */
const saveUser = async ({commit},{data}) =>{
  commit('save_request')
  try {
    const response = await API.post(ApiEndPoints.USERS, data)
    commit('save_success', response) 
    return response
  } catch (error) {
    commit('users_error')
    throw new Error(error.message)
  }
}

/**
 * Inizializza il giro per la creazione di un nuovo utente
 */
const createUser = async ({commit},user) =>{
  commit('user_newuser', user)
}

const clearList = async ({commit}) =>{
  commit('users_clear')

}

const remove = async({commit}, id)=>{
  commit('user_request')
  try {
    await API.delete(ApiEndPoints.USERS+'/'+ id )
    commit('remove_success' ,{id})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

const upload = async({commit},fileData)=>{
  
  commit('user_request')
  try {
    const formData = new FormData();
    formData.append('file', fileData.avatar)
    const response = await API.post(ApiEndPoints.USERS+'/'+ fileData.id +'/avatar', formData)
    commit('upload_success' ,{response})
    return response
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}

const getSingle = async({commit}, id)=>{
  
  commit('user_request')
  try {

    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
   
    const endpoint = usePublisherController ? 
      ApiEndPoints.PUBLISHERS+`/user/${id}`
      : ApiEndPoints.USERS+'/'+id

    const {data} = await API.get(endpoint)
    commit('single_get_success', {data}) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}
  
export  const actions = {
  users,
  userScopes,
  changeUserRole,
  filter,
  sort,
  update,
  createUser,
  saveUser,
  getSingle,
  clearList,
  remove,
  upload
}