<template>
  <aside v-if="isLoggedIn" id="nav" class="sidenav">
      <div 
        class="avatar mt16"
        :style="currentRole && (currentRole?.avatar?.id ) 
          ? `background-image:url('${API_BASE +'/file/'+ currentRole.avatar.id}')` 
          : ``"
        @click="toggleAccountMenu"
      ></div>

      <div class="nav-pages">
          <ul class="left-menu bebas nopadding f20" id="pages-link-container">
            <li class="left-menu-item">
              <router-link to="/" v-if="isAdmin()"  class="white">Home</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="canManageUsers" to="/users" class="white">Utenti</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="canManageScopes" to="/userscopes" class="white">Publisher</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="canManageCampaigns" to="/campaigns" class="white">Campagne</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="canManageSocials" to="/socials" class="white">Socials</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="canManageTags" to="/tags" class="white">Target</router-link>
            </li>
            <li class="left-menu-item">
              <router-link v-if="showMockPost" to="/mockup" class="white">Mockup Post</router-link>
            </li>
        </ul>
        <div class="date-container">
          <a class="bebas pt8 pb8 w100 white" href="#" @click.prevent="toggleMenuSize">{{consideredDay}}</a>
        </div>
        <div class="calendar-container mb32">
          <VueCal
                active-view="month"
                class="vuecal--date-picker  vuecal--blue-theme"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                :disable-views="['week']"
                :selected-date ="new Date()"
                :style="{width: '208px', height: '225px'} "
                :clickToNavigate="false"
            >
            </VueCal>
        </div>
        
        <div class="postit-container">
            <textarea  class="indie noborder f20 p8" id="postit" name="postit" rows="4">
              Questo è un postit 
            </textarea>
        </div>
        <ul 
          class="left-menu account-menu bebas nopadding f20"
          :style="accountMenuVisible ? 'top:0' : 'top:-100%'"
        >
            <li class="left-menu-item">
              <a href="#" class="white" @click="showProfile = true">Profilo</a>
              <ProfilePanel v-if="showProfile" @close="showProfile=false"></ProfilePanel>
            </li>
            <li class="left-menu-item">
              <a href="#" class="white" @click.prevent="logout">Logout</a>

            </li>
            <li v-if="realMe && (realMe?.roles[0] === 'Admin' || realMe?.roles[0] === 'Superuser')" class="left-menu-item">
              <a
                v-if="!mocking" 
                href="#" 
                class="white" 
                @click.prevent="switchUser"
              >
                Cambia utente
              </a>
              <a
                v-else
                href="#" 
                class="white" 
                @click.prevent="resetUser"
              >
                Resetta utenza
              </a>
              <fake-user-panel v-if="openMimicUser" @close="openMimicUser=false"></fake-user-panel>
            </li>
            <li class="left-menu-item">
              <a 
                href="#" 
                @click="toggleAccountMenu"
                class="white">
                Chiudi ↑
              </a>
            </li>
        </ul>
      </div>
      
      
    <div class="logo-big"></div>
    <div class="logo-mini"></div>
  </aside>
</template>
<script>
import {computed, ref} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { API_BASE } from '@/api/Endpoints'
import { DO_LOGOUT, REAL_ROLE,SWITCH_ROLE } from './../store/VuexPaths'
import {Roles} from '../utils/RolesAndPolicies'
import{useCapabilities}from '@/utils/Capabilities'
import FakeUserPanel from './user/FakeUserPanel.vue'
import ProfilePanel from '@/components/ProfilePanel'
import VueCal from 'vue-cal'
export default {
  emits: ['toggle-size'],
  components:{
    FakeUserPanel,
    VueCal,
    ProfilePanel
  },
  setup(_, context){
    
    const store = useStore()
    const router = useRouter() 
    const {
      me,
      currentRole,
      realRole,
      realMe,
      canManageUsers,
      canManageTags,
      canManageCampaigns,
      canManageScopes,
      canManageSocials,
      isLoggedIn,
      mocking,
      isAdmin,
      /* isPublisher,
      isConcessionaire */
    } = useCapabilities()
    
    const showRoleSwitch= computed(()=>{
      return store.getters[REAL_ROLE] === 'Superuser' || store.getters[REAL_ROLE] === 'Admin'
    })

    const showMockPost = computed(()=> {
      return isAdmin()
    })
   

    const logout = async () => {
        accountMenuVisible.value= false
        await store.dispatch(DO_LOGOUT)
        router.push('/login')
    }

    //const selectedOptions = ref(currentRole.value.loglevel)

    let possibleRoles = Roles.map(r => r.name)
    
    const accountMenuVisible = ref(false)
    const toggleAccountMenu = ()=>{
      accountMenuVisible.value = !accountMenuVisible.value
    }

    /**
     * se sono Admin non posso vedere come Superuser!
     */
    if(realRole.value !== 'Superuser'){
      possibleRoles.shift()
    }
    const options = ref(possibleRoles)
    
    const handleSelected = selectedOption => {
      store.dispatch(SWITCH_ROLE, {role:selectedOption})
    }

    const toggleMenuSize = ()=>{
      context.emit('toggle-size')
    }

    const today = new Date(Date.now());
    const dtOpts = {  year: 'numeric', month: 'short', day: 'numeric' }
    const consideredDay = ref(today.toLocaleDateString('it-IT', dtOpts))

    const openMimicUser = ref(false)
    const showProfile = ref(false)

    const switchUser = () =>{
        openMimicUser.value = !openMimicUser.value
        accountMenuVisible.value = false
    }
    const resetUser =() =>{
        store.commit('auth/identity_switch_reset')
        accountMenuVisible.value = false
        window.location.reload()
    }

     return{
        isLoggedIn,
        me,
        realRole,
        currentRole,
        logout,
        canManageUsers,
        canManageTags,
        canManageCampaigns,
        canManageSocials,
        canManageScopes,
        showRoleSwitch,
        API_BASE,
        options,
        handleSelected,
        toggleAccountMenu,
        accountMenuVisible,
        toggleMenuSize,
        consideredDay,
        openMimicUser,
        realMe,
        mocking,
        resetUser,
        switchUser,
        showProfile,
        isAdmin,
        showMockPost
    }
  }
}
</script>
<style lang="postcss">
  .sidenav{
    display: flex;
    flex-direction: column;
}
</style>