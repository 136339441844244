

export const timeStampToFormattedDate = (timestamp, options= { year: 'numeric', month: 'short', day: 'numeric' }) =>{
    //const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const date = new Date(timestamp)
    return date.toLocaleDateString('it', options).replace(new RegExp('/', 'g'), '-')
} 
export const timeStampToFormattedDateTime = (timestamp, options= { year: 'numeric', month: 'short', day: 'numeric' }) =>{
    //const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const date = new Date(timestamp)
    return date.toLocaleTimeString('it', options).replace(new RegExp('/', 'g'), '-')
} 


export const dayhours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00'
]


export const strNumToNumber = (numString) =>{
    if(numString.charAt(0) === '0'){
        return parseInt(numString.substr(1,1))
    }else{
        return parseInt(numString)
    }
}

export const diffDates = (start, end ) =>{
    const diffTime = Math.abs(end - start)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays > 1
}


