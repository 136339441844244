/**
 * Bus per una gestione centralizzata degli eventi
 */
const EventsHandler = {
  events: {},
  publish: function (name, data) {
    const handlers = this.events[name]
    if (!!handlers === false) return
    handlers.forEach(function (handler) {
      if (typeof handler === 'function') { handler.call(this, data) }
    })
  },
  subscribe: function (name, handler) {
    let handlers = this.events[name]
    if (!!handlers === false) {
      handlers = this.events[name] = []
    }
    handlers.push(handler)
  },
  unsubscribe: function (name, handler) {
    if (this.events[name] !== undefined) {
      const handlerIdx = this.events[name].indexOf(handler)
      this.events[name].splice(handlerIdx, 1)
    }
  }
}
export { EventsHandler }
