import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {authModule} from './auth/index'
import {userModule} from './user/index'
import {messagesModule} from './messages/index'
import {campaignsModule} from './campaigns/index'
import {socialModule} from './social/index'
import {tagsModule} from './tags/index'
import {userScopeModule} from './userscope/index'
import {postModule} from './post/index'
import {calendarModule} from './calendar/index'
import {socialCampaignsModule} from './socialcampaigns/index'
import {resetModule} from './reset/index'


const persist = createPersistedState({
  //paths: ['auth', 'user', 'userscope','messages', 'campaigns', 'social']
  paths: ['auth']
})

export const store = createStore({
  modules: {
    auth: authModule,
    reset:resetModule,
    user: userModule,
    userscope: userScopeModule,
    messages:messagesModule,
    campaigns:campaignsModule,
    social:socialModule,
    tags:tagsModule,
    post:postModule,
    calendar:calendarModule,
    socialcampaigns:socialCampaignsModule
  },
  plugins: [persist]
})